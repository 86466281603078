import React, { useState, useEffect, useMemo } from 'react';
import { Box, FormField, Select} from 'grommet';
import { Responsive } from './responsive';
import moment from 'moment';
import FlightDetails from './flightDetails';
import FlightTimes from './flightTimes';

export default function GateEvent({ setEventJsonType, setEventJson  }) {
    const [eventType, setEventType] = useState('BoardEvent')
    const [carrier, setCarrier] = useState();
    const [flightNumber, setFlightNumber] = useState();
    const [departureStation, setDepartureStation] = useState();
    const [arrivalStation, setArrivalStation] = useState();
    const [gate, setGate] = useState();
    const [flightTime, setFlightTime] = useState();
    const [etdTime, setETDTime] = useState();
    const [stdTime, setSTDTime] = useState();
    const [gmtTime, setGMTTime] = useState();
    const [status, setStatus] = useState();
    
    const defaultOptions = ['OPEN_GATE','FLIGHT_CLOSED'];
    const boardingOptions = ['BEGIN_BOARDING','BOARDING_GROUP_1','BOARDING_GROUP_2','BOARDING_GROUP_3','BOARDING_GROUP_4','BOARDING_GROUP_5','BOARDING_GROUP_6','BOARDING_GROUP_7','BOARDING_GROUP_8','BOARDING_GROUP_9','FINAL_BOARDING','XON_ALL','BINS_FULL','FIRST_BOARD'];
    const crewOptions = ['CREW_BOARDED_01','CREW_BOARDED_02','CREW_BOARDED_03','CREW_BOARDED_04','CREW_BOARDED_05','CREW_BOARDED_CA','CREW_BOARDED_FO','MIN_CREW_MET'];
    const connectmeOptions = ['CONNECT_ME'];

    const eventJson = useMemo(() => CreateJson(eventType, arrivalStation, carrier, flightNumber, gate, departureStation, flightTime, stdTime, etdTime, gmtTime, status), [eventType,arrivalStation,carrier,flightNumber,gate,departureStation,flightTime,stdTime, etdTime, gmtTime, status]);

    useEffect(() => {
        setEventJson(eventJson);
    }, [setEventJson, eventJson]);

    useEffect(() => {
        setEventJsonType(eventType);
    }, [setEventJsonType, eventType]);

    function getEventOptions(){
        switch(eventType) {
            case 'BoardingEvent':
              return boardingOptions;
            case 'CrewEvent':
                return crewOptions;
            case 'ConnectMeEvent':
                return connectmeOptions;
            default:
              return defaultOptions;
        }
    }  

    return (
        <Box>
            <FlightDetails setCarrier={setCarrier} carrier={carrier} setFlightNumber={setFlightNumber} flightNumber={flightNumber} setDepartureStation={setDepartureStation} departureStation={departureStation} setArrivalStation={setArrivalStation} arrivalStation={arrivalStation} setGate={setGate} gate={gate} />
            <FlightTimes flightTime={flightTime} setFlightTime={setFlightTime} stdTime={stdTime} setSTDTime={setSTDTime} etdTime={etdTime} setETDTime={setETDTime} setGMTTime={setGMTTime} />
            <Responsive margin="medium">
                <FormField label="Event Type" >
                    <Select className="data" name="eventtype" options={['DefaultEvent','BoardingEvent','CrewEvent','ConnectMeEvent']} value={eventType} onChange={({ option }) => setEventType(option)} required />
                </FormField>
                <FormField label="Event" >
                    <Select className="data" name="status" options={getEventOptions()} value={status} onChange={({ option }) => setStatus(option)} required />
                </FormField>
            </Responsive>
        </Box>
    );
};

function CreateJson(eventType, arrivalStation, carrier, flightNumber, gate, departureStation, flightTime, stdTime, etdTime, gmtTime, status) {
    const eventTime = moment().utc().format('YYYY-MM-DDTHH:mm:ssZ');
    switch(eventType) {
        case 'BoardingEvent':
            return {
                "?xml": {
                "@version": "1.0",
                "@encoding": "utf-8"
                },
                "GateEvent": {
                    "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                    "@xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
                    "@xmlns": "http://tempuri.org/XMLSchema.xsd",
                "FlightInfo":{
                    "ArrStation": arrivalStation,
                    "Carrier": carrier,
                    "FlightNumber": flightNumber,
                    "Gate": gate,
                    "DepStation": departureStation,
                    "FlightDate": flightTime,
                    "GMTDepDateTime": gmtTime,
                    "SchDepTime": stdTime,
                    "EstDepTime": etdTime,
                    "EventTime": eventTime
                },
                "Event":{
                    "@xsi:type":"BoardingEvent",
                    "Status": status,
                    "Remarks": status !== 'XON_ALL' ? "Update GIDS: Boarding Groups for Flight " + flightNumber : "XON'd flight " + flightNumber,
                    "BinsFull": status === 'BINS_FULL' ? true : false
                }
            }
            };
        case 'CrewEvent':
            return {
                "?xml": {
                "@version": "1.0",
                "@encoding": "utf-8"
                },
                "GateEvent": {
                    "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                    "@xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
                    "@xmlns": "http://tempuri.org/XMLSchema.xsd",
                "FlightInfo":{
                    "ArrStation": arrivalStation,
                    "Carrier": carrier,
                    "FlightNumber": flightNumber,
                    "Gate": gate,
                    "DepStation": departureStation,
                    "FlightDate": flightTime,
                    "GMTDepDateTime": gmtTime,
                    "SchDepTime": stdTime,
                    "EstDepTime": etdTime,
                    "EventTime": eventTime
                },
                "Event":{
                    "@xsi:type":"CrewEvent",
                    "Status": status,
                    "Remarks": status !== 'MIN_CREW_MET' ? "Crew Boarded Event for Flight " + flightNumber : "Minimum Crew Event for Flight " + flightNumber,
                    "MinimumCrew": status === 'MIN_CREW_MET' ? true : false,
                    "PositionCode": status === "" || 'MIN_CREW_MET' ? '01' : status.slice(-2),
                    "EmpNumber":"306439"
                }
            }
            };
        case 'ConnectMeEvent':
            return {
                "?xml": {
                "@version": "1.0",
                "@encoding": "utf-8"
                },
                "GateEvent": {
                    "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                    "@xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
                    "@xmlns": "http://tempuri.org/XMLSchema.xsd",
                "FlightInfo":{
                    "ArrStation": arrivalStation,
                    "Carrier": carrier,
                    "FlightNumber": flightNumber,
                    "Gate": gate,
                    "DepStation": departureStation,
                    "FlightDate": flightTime,
                    "GMTDepDateTime": gmtTime,
                    "SchDepTime": stdTime,
                    "EstDepTime": etdTime,
                    "EventTime": eventTime
                },
                "Event":{
                    "@xsi:type":"ConnectMeEvent",
                    "Status": status,
                    "Remarks": "Connect Me Event for Flight " + flightNumber,
                    "AAAgentId":"815535"
                }
            }
            };
        default:
            return {
                "?xml": {
                "@version": "1.0",
                "@encoding": "utf-8"
                },
                "GateEvent": {
                    "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                    "@xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
                    "@xmlns": "http://tempuri.org/XMLSchema.xsd",
                "FlightInfo":{
                    "ArrStation": arrivalStation,
                    "Carrier": carrier,
                    "FlightNumber": flightNumber,
                    "Gate": gate,
                    "DepStation": departureStation,
                    "FlightDate": flightTime,
                    "GMTDepDateTime": gmtTime,
                    "SchDepTime": stdTime,
                    "EstDepTime": etdTime,
                    "EventTime": eventTime
                },
                "Event":{
                    "@xsi:type":"DefaultEvent",
                    "Status": status,
                    "Remarks":"Update GIDS: Gate Operation for Flight " + flightNumber
                }
            }
        };
    }
}