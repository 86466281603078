import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const CustomBreakpoints = deepMerge(grommet, {
    global: {
        breakpoints: {
            small: {
                value: 600,
            },
            medium: {
                value: 900,
            },
            large: {
                value: 3000,
            },
        },
        colors: {
            brand: '#135393', //blue
            'brand-contrast': '#e7ebee', //grey
            active: '#127cb8 ', //darker blue
            focus: '#135393'
        },
        Button: {
            active: {
                background: { color: 'brand-contrast' },
                color: 'brand-contrast',
                border: 'brand-contrast',
                secondary: {
                    background: 'brand-contrast',
                    border: {
                        color: 'brand-contrast',
                    }
                }
            }
        }
    },
});

export { CustomBreakpoints }