import React, { useRef, useState } from "react";
import { CustomBreakpoints } from './grommetTheme';
import IdleTimer from "react-idle-timer";
import { Box, Button, Grommet, Layer } from 'grommet';
import { Send, Logout } from 'grommet-icons';

function IdleTimerContainer(){
    const[isModalOpen, setIsModalOpen] = useState(false);

    const idleTimerRef = useRef(null);

    const onIdle = () => {
        setIsModalOpen(true);
    }

    const stayActive = () => {
        setIsModalOpen(false);
    }

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }

    return (
        <Grommet theme={CustomBreakpoints}>
            <Box>
                {isModalOpen && (
                    <Layer onEsc={() => setIsModalOpen(false)} onClickOutside={() => setIsModalOpen(false)} >
                        <IdleTimer ref={idleTimerRef} timeout={60000}  onIdle={logout}></IdleTimer>
                        <Box margin={{top: "-0.5em"}} pad={{left: "1.1em"}}>
                            <p>Are you still there?</p>
                        </Box>
                        <Box direction="column" pad={{top: "-1em", bottom: "-1em"}} margin={{top: "-1em", bottom: "-1em"}} fill="horizontal" justify='center' align="center">
                            <h2>Would you like to Continue?</h2>
                        </Box>
                        <Box direction="row" gap="medium" pad="medium" fill="horizontal" justify='center' align="center">
                            <Button icon={<Send color="white" />} label='Continue' id='sendButton' onClick={stayActive} />
                            <Button icon={<Logout color="white" />} label='Logout' id='sendButton' onClick={logout} />
                        </Box>
                        <Box direction="column" pad={{top: "-1em", bottom: "-1em"}} margin={{top: "-2em"}} fill="horizontal" justify='center' align="center">
                            <p>(session will end in 60 seconds)</p>
                        </Box>
                    </Layer>
                )}
                <IdleTimer ref={idleTimerRef} timeout={900000} onIdle={onIdle}></IdleTimer>
            </Box>
        </Grommet>
    )
}

export default IdleTimerContainer