import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const session = sessionStorage.getItem('session');
    const tokenString = localStorage.getItem('token');
    if(tokenString == null || session == null) return null;
    return tokenString
  };
  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken.token));
    sessionStorage.setItem('session', userToken.session);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}