import { Responsive } from './responsive';
import DateTimeInput from './dateTimeInput';

export default function FlightTimes({ flightTime, setFlightTime, stdTime, setSTDTime, etdTime, setETDTime, gmtTime, setGMTTime }) {
       
    return (
        <Responsive margin="medium">
            <DateTimeInput label="Flight Date Time" value={flightTime} state={setFlightTime} />                
            <DateTimeInput label="STD Date Time" value={stdTime} state={setSTDTime}/>               
            <DateTimeInput label="ETD Date Time" value={etdTime} state={setETDTime} />
            <DateTimeInput label="GMT Date Time" value={gmtTime} state={setGMTTime} />
        </Responsive>
    );
};