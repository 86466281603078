import { FormField, MaskedInput, Tip} from 'grommet';

export default function FormInput({fieldName, length, regex, defaultValue, value, state}) {

    return (
        <Tip content={fieldName} >
            <FormField name={fieldName} htmlFor={fieldName+'MI'} label={fieldName} >
                <MaskedInput 
                    mask={[
                    {
                        length: length,
                        regexp: regex,
                        placeholder: defaultValue,
                    }]} name={fieldName+'MI'} regex={/^[A-Z]{2}$/} value={value} onChange={event=> state(event.target.value)} required/>
            </FormField>
        </Tip>
    );
}