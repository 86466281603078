import { FormField, MaskedInput, Tip } from 'grommet';

export default function DateTimeInput({ label, value, state}) {
   
    return (
        <Tip content={label} >
            <FormField label={label} >
                <MaskedInput 
                    mask={[
                    {
                        length: 4,
                        options: ['2022', '2023', '2024', '2025', '2026'],
                        regexp: /^[2][0]?[2]?[2-6]?$/,
                        placeholder: 'YYYY',
                    },
                    { fixed: '-' },
                    {
                        length: 2,
                        options: [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                        regexp: /^[0-1]?[0-9]?$/,
                        placeholder: 'MM',
                    },
                    { fixed: '-' },
                    {
                        length: 2,
                        options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31' ],
                        regexp: /^[0-3]?[0-9]?$/,
                        placeholder: 'dd',
                    },
                    { fixed: 'T' },
                    {
                        length: 2,
                        options: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23' ],
                        regexp: /^[0-2]?[0-9]?$/,
                        placeholder: 'hh',
                    },
                    { fixed: ':' },
                    {
                        length: 2,
                        options: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
                        regexp: /^[0-2]?[0-9]?$/,
                        placeholder: 'mm',
                    },
                    { fixed: ':' },
                    {
                        length: 2,
                        options: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
                        regexp: /^[0-2]?[0-9]?$/,
                        placeholder: 'ss',
                    },
                    { fixed: '-' },
                    {
                        length: 5,
                        options: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                        regexp: /^[0-1]?[0-4]?:?[0]?[0]?$/,
                        placeholder: 'offset',
                    }
                    ]} value={value}  onChange={event=> state(event.target.value)} required/>
            </FormField>
        </Tip>
    );
};