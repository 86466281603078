import React, { useState } from 'react';
import { Grommet, Box, Button, Form, Heading } from 'grommet';
import { CustomBreakpoints } from './grommetTheme';
import { Send } from 'grommet-icons';
import GateEvent from './gateEvent';
import useToken from '../useToken';

async function SendEvent(eventHub, event) {
    return fetch('/admin/event/sendEvent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Token': useToken.Token
        },
        body: JSON.stringify({eventHub, event})
    })
    .then((response) => {
        if (response.status === 401){ 
            localStorage.removeItem('token');
            window.location.reload();
        }else{
            return response.json();
        }
    });
}

export default function SimulatorSendEvent({ errorMessage }) {
    const [eventJsonType, setEventJsonType] = useState();
    const [eventJson, setEventJson] = useState();
    const [resultMessage, setResultMessage] = useState();

    function GetEventHubName(){
        switch(eventJsonType) {
            case 'CrewEvent':
                return 'creweventhub';
            case 'ConnectMeEvent':
                return 'connectmeeventhub';
            case 'BoardingEvent':
            default:
                return 'boardeventhub';
        };
    }

    const handleSubmit = async e => {
        setResultMessage(null);
        const eventName = GetEventHubName();
        
        const result = await SendEvent(
            eventName,
            eventJson
        );

        setResultMessage(result.result);
    };

    return (
        <Grommet theme={CustomBreakpoints}>
            <Box margin="small">
                <Form onSubmit={handleSubmit}>
                    <Box direction="row" gap="xlarge" pad="xsmall" fill="horizontal" justify='center' align="center">
                        <Heading align="center" level={2}>Send Event</Heading>
                        <Button icon={<Send color="white" />} label='Send Event' id='sendButton' type="submit" />
                    </Box>
                    
                    <GateEvent setEventJsonType={setEventJsonType} setEventJson={setEventJson} />

                    {resultMessage ? <div>
                                        <Box direction="row" pad="xsmall" fill="horizontal" justify='center' align="center"><h3>{resultMessage}</h3></Box>
                                        <Box direction="row" pad="xsmall" fill="horizontal" justify='center' align="center"><h4>{JSON.stringify(eventJson)}</h4></Box>
                                    </div>
                        : <Box direction="row" pad="xsmall" fill="horizontal" justify='center' align="center"><h3 className="warningLabel">{errorMessage}</h3></Box>}
                </Form>
            </Box>
        </Grommet>
    );
};