import React, { useState } from 'react';
import PropTypes from 'prop-types';
import aaLogoBig from '../images/aaLogoBig.png';

import { Box, Button, Grommet, Form, FormField, Image, Grid, Heading } from 'grommet';

const theme = {
    global: {
        colors: {
            brand: '#135393', //blue
            'brand-contrast': '#e7ebee', //grey
            active: '#127cb8 ', //darker blue
            focus: '#135393'
        },
    },
    button: {
        default: {
            color: 'brand-contrast',
            border: 'brand-contrast',
            padding: {
                horizontal: '12px',
                vertical: '8px',
            },
        },
        primary: {
            background: { color: 'brand' },
            color: 'brand-contrast',
            border: 'brand-contrast',
            padding: {
                horizontal: '24px',
                vertical: '8px',
            },
        },
        active: {
            background: { color: 'brand-contrast' },
            color: 'brand-contrast',
            border: 'brand-contrast',
            secondary: {
                background: 'brand-contrast',
                border: {
                    color: 'brand-contrast',
                },
            },
        },
        disabled: {
            opacity: 0.3,
            secondary: {
                border: { color: 'text-weak' },
            },
        },
        hover: {
            background: { color: 'brand-contrast' },
            border: 'brand-contrast',
            secondary: {
                border: { color: 'brand-contrast' },
            },
        },
    },
    formField: {
        border: { color: 'brand-contrast' }
    }
};

async function signInUser(userName, password) {
    return fetch('/admin/user/signin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({userName, password})
    })
    .then(data => data.json())
}

export default function SignIn({ setToken }) {
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [valid, setValid] = useState(false);
    const [errorMessage, isSignInError] = useState();

    const handleSubmit = async e => {
        const token = await signInUser(
            userName,
            password
        );

        if (token.error !== undefined && token.error.error_description !== undefined) isSignInError("An error has occured. Contact site admin. " + token.error.error_description)
        else if (!token.token) isSignInError(token.error)
        else setToken(token);
    }

    return (
        <Grommet full theme={theme}>
            <Grid
                fill
                rows={['auto', 'flex']}
                columns={['auto', 'flex']}
                areas={[
                    { name: 'logo', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [1, 1], end: [1, 1] },
                ]}>
                <Box fill align="center" gridArea="logo"><Image src={aaLogoBig} /></Box>
                <Box fill align="center" gridArea="main">
                    <Box width="large" align='center'>
                        <Heading className="heading" align="center">GateEvents Simulator</Heading>
                        <Box width="medium">
                            <Form
                                validate="change"
                                onSubmit={handleSubmit}
                                onValidate={(validationResults) => {
                                    setValid(validationResults.valid);
                                }}>
                                <FormField
                                    label="username"
                                    name="username"
                                    id="username"
                                    required
                                    validate={[
                                        { regexp: /^[a-z]/i },
                                        (username) => {
                                            if (username && username.length === 1)
                                                return 'must be >1 character';
                                            return undefined;
                                        },
                                    ]}
                                    onChange={e => setUserName(e.target.value)}/>

                                <FormField
                                    label="password"
                                    name="password"
                                    id="password"
                                    type="password"
                                    required
                                    validate={[
                                        (password) => {
                                            if (password && password.length === 1)
                                                return 'must be >1 character';
                                            return undefined;
                                        },
                                    ]}
                                    onChange={e => setPassword(e.target.value)}/>
                                <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                                    <Button type="submit" label="Sign In" disabled={!valid} primary />
                                </Box>
                                {isSignInError ? (<h3 align="center" className="warningLabel">{errorMessage}</h3>) : null}
                            </Form>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grommet>
    )
}

SignIn.propTypes = {
    setToken: PropTypes.func.isRequired
}