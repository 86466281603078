import React from 'react';
import { Link } from 'react-router-dom';
import aaSmall from '../images/aaSmall.png'
import { Button, Box, Grommet, Nav, Sidebar, Tip, Avatar, Text } from 'grommet';
import { Logout } from 'grommet-icons';

const theme = {
    global: {
        colors: {
            brand: '#135393', //blue
            'brand-contrast': '#e7ebee', //grey
            active: '#127cb8 ', //darker blue
            focus: '#135393'
        },
        button: {
            default: {
                color: 'brand-contrast',
                border: 'brand-contrast',
                padding: {
                    horizontal: '12px',
                    vertical: '8px',
                },
            },
            primary: {
                background: { color: 'brand' },
                color: 'brand-contrast',
                border: 'brand-contrast',
                padding: {
                    horizontal: '24px',
                    vertical: '8px',
                },
            }
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
          },
    }
}
const SidebarHeader = () => (
    <Avatar
    flex={false}
  >
    <img src={aaSmall} width="75px" alt="logo"></img>
  </Avatar>
);

const logOut = () => {
    localStorage.removeItem('token');
    window.location.reload()
};

const SidebarFooter = () => (
    <Nav gap="small">
        <SidebarButton icon={<Logout />} label="Logout" link="" onClick={logOut}/>
    </Nav>
);

const SidebarButton = ({ icon, label, link, ...rest }) => (
    <Box pad="small">
        <Tip
            dropProps={{ align: { left: 'right' } }}
            content={<TipContent message={label}/>}
            plain
          >
        <Link to={link}>
            <Button
                gap="medium"
                alignSelf="start"
                plain
                icon={icon}
                {...rest}
            />
        </Link>
        </Tip>
    </Box>
);

const TipContent = ({ message }) => (
    <Box direction="row" align="center">
      <svg viewBox="0 0 22 22" version="1.1" width="22px" height="22px">
        <polygon
          fill="grey"
          points="6 2 18 12 6 22"
          transform="matrix(-1 0 0 1 30 0)"
        />
      </svg>
      <Box background="grey" direction="row" pad="small" round="xsmall">
        <Text color="white">{message}</Text>
      </Box>
    </Box>
  );

export default function SidebarComponent() {
    return (
        <Grommet theme={theme} full>
            <Box direction="row" height={{ min: '100%' }}>
                
                    <Sidebar
                        background="brand"
                        header={<SidebarHeader />}
                        footer={<SidebarFooter />}
                        id='sidebar'
                    >
                    </Sidebar>
            </Box>
        </Grommet>
    )
};
