import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import IdleTimerContainer from './components/idleTimerContainer';
import SignIn from './pages/signIn';
import SidebarComponent from './components/sidebar'
import SimulatorSendEvent from './components/simulatorSendEvent'
import { Grommet, Grid, Box } from 'grommet';
import './App.css';

import useToken from './useToken';

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    colors: {
      'aaRed': '#eb2023',
      'aaBlue': '#135393',
      'aaWhite': '#e7ebee',
      'aaBlack': '#000000'
    },
    text: {
      dark: 'black',
      light: 'aaWhite',
    }
  },
};

function App() {

  const { token, setToken } = useToken();
  
  if (!token) {
    return (
      <Grommet theme={theme} full>
        <Box justify="center" align="center"><SignIn setToken={setToken} /></Box>
      </Grommet>)
  }
  return (
    <Grommet theme={theme} full>
      <IdleTimerContainer></IdleTimerContainer>
      <BrowserRouter>
        <Grid
          fill
          rows={['auto', 'flex']}
          columns={['xsmall', 'flex']}
          areas={[
            { name: 'sidebar', start: [0, 0], end: [0, 1] },
            { name: 'header', start: [1, 0], end: [1, 0] },
            { name: 'main', start: [1, 1], end: [1, 1] }
          ]}
          >
          <Box gridArea="sidebar" width="xxsmall"><SidebarComponent /></Box>
          <Box gridArea="header"  align="start" width="large" id='header'><h1 id='heading'>GateEvents Simulator</h1></Box>
          <Box gridArea="main"><SimulatorSendEvent /></Box>
        </Grid>
      </BrowserRouter>
    </Grommet>
  );
};

export default App;