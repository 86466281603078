import { Responsive } from './responsive';
import FormInput from './formInput';

export default function FlightDetails({ setCarrier, carrier, setFlightNumber, flightNumber, setDepartureStation, departureStation, setArrivalStation, arrivalStation, setGate, gate }) {
       
    return (
        <Responsive margin="medium">
            <FormInput fieldName="Carrier" length={2} regex={/^[A-Z]?[A-Z]?$/} defaultValue="AA" value={carrier} state={setCarrier} />
            <FormInput fieldName="Flight Number" maxLength={4} regex={/^[0-9]?[0-9]?[0-9]?[0-9]?$/} defaultValue="0000" value={flightNumber} state={setFlightNumber} />
            <FormInput fieldName="Departure Station" maxLength={3} regex={/^[A-Z]?[A-Z]?[A-Z]?$/} defaultValue="DFW" value={departureStation} state={setDepartureStation} />
            <FormInput fieldName="Arrival Station" maxLength={3} regex={/^[A-Z]?[A-Z]?[A-Z]?$/} defaultValue="PHX" value={arrivalStation} state={setArrivalStation} />
            <FormInput fieldName="Gate" length={3} regex={/^[A-Z0-9]?[A-Z0-9]?[A-Z0-9]?$/} defaultValue="A1" value={gate} state={setGate} />
        </Responsive>
    );
};